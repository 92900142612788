/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form, Input } from 'antd';
import { FocusEventHandler, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  ConfigRevision,
  Node,
  NodeConfig,
  NodeHallmark,
  NodeLifecycleEvent,
  OperationalStateChangeResp,
} from '@/client';
import { BackButton } from '@/components/Buttons/BackButton';
import { NodeIcon } from '@/components/Icons/NodeIcon';
import { Hallmarks } from '@/components/Node/Hallmarks';
import { IncomingData } from '@/components/Node/IncomingData';
import { LifecycleEvents } from '@/components/Node/LifecycleEvents';
import { NodeCredentials } from '@/components/Node/NodeCredentials';
import { NodeHeartbeats } from '@/components/Node/NodeHeartbeats';
import { NodeState } from '@/components/Node/NodeState';
import { NodeViewPageCard } from '@/components/Node/NodeViewPageCard';
import { PageHeader } from '@/components/PageHeader';
import { TagsField } from '@/components/TagsField';
import { Col } from '@/primitives/Col';
import { Container } from '@/primitives/Container';
import { Flex } from '@/primitives/Flex';
import { Row } from '@/primitives/Row';
import { Skeleton } from '@/primitives/Skeleton';
import { Space } from '@/primitives/Space';
import { Text } from '@/primitives/Text';
import { useMessage } from '@/primitives/message';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { tokens } from '@/theme';
import { getHallmarks } from '@/utils/hooks/getHallmarks';
import { getNodeLifeCycleEvents } from '@/utils/hooks/getNodeLifeCycleEvents';
import { getNodeOptions } from '@/utils/hooks/getNodeOptions';
import { getTagListOptions } from '@/utils/hooks/getTagListOptions';
import { getUpdateNodeOperationalStateOptions } from '@/utils/hooks/getUpdateNodeOperationalStateOptions';
import { useAddTagMutation } from '@/utils/hooks/useAddTagMutation';
import { useNewTagMutation } from '@/utils/hooks/useNewTagMutation';
import { useQueryParams } from '@/utils/hooks/useQueryParams';
import { useRemoveTagMutation } from '@/utils/hooks/useRemoveTagMutation';
import { useUpdateNodeMutation } from '@/utils/hooks/useUpdateNodeMutation';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { EditableNodeName } from './EditableNodeName';
import { Collapse } from '@/primitives/Collapse';
import { NodeConfigState } from '@/components/NodeConfigState/NodeConfigState';
import { ConfigWizardOverlay } from '@/components/ConfigWizardOverlay/ConfigWizardOverlay';
import { useAssignConfigRevisionMutation } from '@/utils/hooks/useAssignConfigRevisionMutation';
import { useSubscribeToEvents } from '@/utils/hooks/useSubscribeToEvents';

export type NodeViewPageProps = {
  id: string;
};

export function NodeViewPage({ id }: NodeViewPageProps) {
  const { t } = useMimicTranslation('node');
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const [nodeToShow, setNodeToShow] = useState<Node | undefined>();
  const [selectedNodeConfig, setSelectedNodeConfig] = useState<NodeConfig | undefined>();
  const [selectedConfigRevision, setSelectedConfigRevision] = useState<ConfigRevision | undefined>();
  const { isPending, isError, data, error, refetch } = useQuery(getNodeOptions(tenantID!, id));
  const assignConfigRevision = useAssignConfigRevisionMutation(tenantID!, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['node', data!.tenantId, data!.id] });
    },
  });

  useSubscribeToEvents('node:operational-state-changed', (response: OperationalStateChangeResp) => {
    if (response.data.nodeId !== id) return;
    queryClient.invalidateQueries({ queryKey: ['node', tenantID, id] });
  });

  const { paginationParams, updateQueryParams } = useQueryParams<NodeLifecycleEvent, unknown, string[]>({});
  const { paginationParams: hallmarksPaginationParams, updateQueryParams: updateHallmarksQueryParams } = useQueryParams<
    NodeHallmark,
    unknown,
    string[]
  >({}, 'hallmarks');

  const [message] = useMessage();
  const { mutateAsync: addTag } = useAddTagMutation(tenantID!);
  const { mutateAsync: removeTag } = useRemoveTagMutation(tenantID!);
  const { mutateAsync: newTagMutate } = useNewTagMutation(tenantID!);

  const mutationConfig = getUpdateNodeOperationalStateOptions(data!, tenantID!, queryClient);

  const { mutate } = useUpdateNodeMutation(tenantID!, data!, {
    onSuccess: () => {
      message.success(t('feedback.nodeUpdated'));
    },
    onError: () => {
      message.error(t('feedback.updateFailed'));
    },
  });

  if (isError) {
    if (error.status === 404) {
      return <Navigate to={`/tenants/${tenantID}/nodes`} replace />;
    }
    throw error;
  }

  if (!data) {
    return <Skeleton loading />;
  }
  const nodeTags = data.tags || [];

  const hallmarkOptions = getHallmarks(tenantID!, data.id, hallmarksPaginationParams);
  const nodeLifeCycleEventsOptions = getNodeLifeCycleEvents(tenantID!, data.id, paginationParams);

  const onDescriptionBlur: FocusEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.target.value === null || event.target.value === undefined) return;
    const newDescription = event.target.value.trim();
    if (newDescription === data.description) return;
    mutate({ description: newDescription });
  };

  const onNameChange = (newNodeName: string) => {
    mutate({ name: newNodeName });
  };

  if (!tenantID) return null;

  const onAddTag = async (tagName: string) => {
    await addTag({ nodeId: id, tagName });
    await refetch();
  };

  const onRemoveTag = async (tagName: string) => {
    await removeTag({ nodeId: id, tagName });
  };

  const onCreateTag = async (name: string) => {
    await newTagMutate({ name });
  };

  const onCloseConfigWizard = () => {
    setNodeToShow(undefined);
  };

  const onAssignConfigRevision = (node: Node, configRevision: ConfigRevision) => {
    if (!node || !configRevision?.nodeConfigId) throw new Error('Node or nodeConfigId are missing');

    assignConfigRevision.mutate({
      nodeIDs: [data.id],
      configID: configRevision.nodeConfigId,
      revisionNumber: configRevision.revisionNumber,
    });
    setNodeToShow(undefined);
  };

  return (
    <Container>
      <BackButton to={`/tenants/${tenantID}/nodes`} />
      <PageHeader
        icon={<NodeIcon />}
        title={
          <Skeleton loading={isPending} title width="xxl">
            <Text mono>{data.systemProfile?.['host.name'] || t('hostNameNotAvailable')}</Text>
          </Skeleton>
        }
        text={
          <Space dir="vertical">
            <Row>
              <Skeleton loading={isPending} title width="xl">
                <Container style={{ width: '40vw' }}>
                  <EditableNodeName name={data.name} onNameChange={onNameChange} />
                </Container>
              </Skeleton>
            </Row>
            <TagsField
              selectedTags={nodeTags}
              getTagListOptions={getTagListOptions}
              tenantID={tenantID}
              onAddTag={onAddTag}
              onRemoveTag={onRemoveTag}
              onCreateTag={onCreateTag}
            />
          </Space>
        }
        level={4}
        fontWeight="300"
      />
      <Row gutter={16}>
        <Col span={24}>
          <NodeState isLoading={isPending} node={data} mutationConfig={mutationConfig} />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Collapse
            data-testid="hallmark-collapse"
            items={[
              {
                key: '1',
                label: (
                  <div
                    style={{ fontSize: '1.125rem', fontWeight: 700, backgroundColor: tokens.color.surface.surfaceHigh }}
                  >
                    {t('hallmarks')}
                  </div>
                ),
                children: (
                  <div data-testid="hallmarks-section">
                    <Hallmarks options={hallmarkOptions} onParamsChange={updateHallmarksQueryParams} />
                  </div>
                ),
              },
            ]}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Flex vertical>
            <NodeViewPageCard title={t('lifecycle_events')} dataTestId="lifecycle-events-section">
              <LifecycleEvents options={nodeLifeCycleEventsOptions} onParamsChange={updateQueryParams} />
            </NodeViewPageCard>
            <NodeViewPageCard title={t('node_description')}>
              <Form form={form} initialValues={data} layout="vertical">
                <Form.Item name="description">
                  <Input.TextArea
                    allowClear
                    rows={4}
                    onBlur={onDescriptionBlur}
                    placeholder={t('node_description_placeholder')}
                    count={{ show: true, max: 280 }}
                    maxLength={280}
                    style={{
                      backgroundColor: tokens.color.surface.surfaceHigh,
                    }}
                  />
                </Form.Item>
              </Form>
            </NodeViewPageCard>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex vertical>
            <NodeConfigState
              node={data}
              assignNewNodeConfig={() => setNodeToShow(data)}
              assignNewConfigRevision={() => {
                setSelectedNodeConfig(data.appliedRevisionState?.nodeConfig as unknown as NodeConfig);
                setSelectedConfigRevision(data.appliedRevisionState?.configRevision as ConfigRevision);
                setNodeToShow(data);
              }}
              getNodeConfigPath={(node: Node) =>
                `/tenants/${tenantID}/node-configs/${node.appliedRevisionState?.nodeConfig?.id}`
              }
              getNodeConfigRevisionPath={(node: Node) =>
                `/tenants/${tenantID}/node-configs/${node.appliedRevisionState?.nodeConfig?.id}/revisions/${node.appliedRevisionState?.configRevision?.revisionNumber}`
              }
            />
            <NodeViewPageCard title={t('server_configuration')}>
              <Form layout="vertical">
                <Row>
                  <Col span={12}>
                    <IncomingData
                      label={t('ip_address')}
                      loading={isPending}
                      text={data.systemProfile?.['host.ip']}
                      mono
                      copyable
                    />
                  </Col>
                  <Col span={12}>
                    <IncomingData
                      label={t('os')}
                      loading={isPending}
                      text={
                        data.systemProfile
                          ? `${data.systemProfile['os.name']} ${data.systemProfile['os.version']}`
                          : undefined
                      }
                      mono
                      copyable
                    />
                  </Col>
                </Row>
              </Form>
            </NodeViewPageCard>
            <NodeCredentials node={data} isPending={isPending} />
            <NodeHeartbeats data={data} isPending={isPending} />
          </Flex>
        </Col>
      </Row>
      {nodeToShow ? (
        <ConfigWizardOverlay
          node={nodeToShow}
          defaultNodeConfig={selectedNodeConfig}
          defaultConfigRevision={selectedConfigRevision}
          onCancel={onCloseConfigWizard}
          onAssign={onAssignConfigRevision}
        />
      ) : null}
    </Container>
  );
}
