/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Typography } from 'antd';
import { ParagraphProps } from 'antd/es/typography/Paragraph';
import { TextProps as AntDTextProps } from 'antd/es/typography/Text';
import { FontSize, tokens } from '@/theme';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';

const { Text: AntDText } = Typography;

export type TextType = 'default' | 'subtle' | 'error' | 'disabled' | 'link';

const TypeToAntDType: Record<TextType, string> = {
  default: tokens.color.text.textDefault,
  subtle: tokens.color.text.textSubtle,
  error: tokens.color.error.errorText,
  disabled: tokens.color.text.textDisabled,
  link: tokens.color.components.link.linkDefault,
};

export type TextProps = {
  type?: TextType;
  size?: FontSize;
  lineHeight?: string;
  strong?: boolean;
  italic?: boolean;
  children?: React.ReactNode;
  ellipsis?: boolean;
  copyable?: boolean | AntDTextProps['copyable'];
  mono?: boolean;
  onClick?: AntDTextProps['onClick'];
  style?: React.CSSProperties;
  className?: AntDTextProps['className'];
  ['data-testid']?: string;
};

export function Text({
  type,
  size,
  lineHeight,
  strong,
  italic,
  ellipsis,
  children,
  mono,
  onClick,
  style,
  copyable,
  className,
  'data-testid': dataTestId,
}: TextProps) {
  const { t } = useMimicTranslation('global');
  const fontSize = size ? tokens.size.font[size] : 'inherit';
  const fontColor = type ? TypeToAntDType[type] : 'inherit';
  const fontStyle = italic ? 'italic' : undefined;
  const fontFamily = mono ? tokens.font.monospace.fontFamily : tokens.font.normal.fontFamily;
  const fontWeight = mono ? tokens.font.monospace.fontWeight : tokens.font.normal.fontWeight;
  let copyProps: boolean | ParagraphProps['copyable'] = copyable === undefined ? false : copyable;

  if (copyProps) {
    copyProps = {
      tooltips: [t('copy'), t('copied')],
    };
  }

  return (
    <AntDText
      className={className}
      strong={strong}
      style={{ fontFamily, fontWeight, fontSize, color: fontColor, lineHeight, fontStyle, ...style }}
      ellipsis={ellipsis}
      copyable={copyable}
      data-testid={dataTestId}
      onClick={onClick}
    >
      {children}
    </AntDText>
  );
}
