/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { BuildOutlined, WarningFilled } from '@ant-design/icons';
import { UseQueryResult } from '@tanstack/react-query';
import { ColumnsType, ColumnType } from 'antd/es/table/interface';
import { useState } from 'react';
import { ConfigRevision, NodeConfig, NodeConfigFilters, PaginatedNodeConfigsResponse, Tag } from '@/client';
import { PageHeader } from '@/components/PageHeader';
import { PaginatedTable } from '@/components/PaginatedTable';
import { Container } from '@/primitives/Container';
import { Flex } from '@/primitives/Flex';
import { Link } from '@/primitives/Link';
import { Radio } from '@/primitives/Radio';
import { Tooltip } from '@/primitives/Tooltip';
import { tokens } from '@/theme';
import { UpdateParams } from '@/utils/params';
import { MimicTranslationFunction, useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { OsIcon } from './Icons';
import MimicHighlighter from './MimicHighlighter';
import { RelativeTime } from './RelativeTime';
import { TagsList } from './TagsList';
import { Text } from '@/primitives/Text';

export type SelectableNodeConfig = NodeConfig & { selected: boolean };

export type NodeConfigsListProps = {
  tags: Tag[] | undefined;
  onFilterAndSortChange: UpdateParams<NodeConfig, Partial<NodeConfigFilters>>;
  nodeConfigsQueryResponse: UseQueryResult<PaginatedNodeConfigsResponse, Error>;
  getNodeConfigPath: (nodeConfig: NodeConfig) => string;
  getNodeConfigurationRevisionPath: (nodeConfigRevision: ConfigRevision) => string;
  onNodeConfigSelect?: (nodeConfig: NodeConfig) => void;
  defaultNodeConfig?: NodeConfig;
};

export function NodeConfigsList({
  tags,
  onFilterAndSortChange,
  nodeConfigsQueryResponse,
  getNodeConfigPath,
  getNodeConfigurationRevisionPath,
  onNodeConfigSelect,
  defaultNodeConfig,
}: NodeConfigsListProps) {
  const { t } = useMimicTranslation('nodeConfigurations');
  const [selectedNodeConfig, setSelectedNodeConfig] = useState<NodeConfig | undefined>(defaultNodeConfig);

  const onSelect = onNodeConfigSelect
    ? (nodeConfig: SelectableNodeConfig) => {
        setSelectedNodeConfig(nodeConfig);
        onNodeConfigSelect(nodeConfig);
      }
    : undefined;

  const columns = getNodeConfigurationColumns(
    t,
    tags,
    nodeConfigsQueryResponse.data?.meta?.filters || {},
    getNodeConfigPath,
    getNodeConfigurationRevisionPath,
    onSelect,
  );

  const dataSourceMapper = (data: NodeConfig) => {
    const mappedData = {
      ...data,
      selected: selectedNodeConfig?.id === data.id,
    };
    return mappedData;
  };

  return (
    <Container full style={{ width: '100%' }}>
      <PageHeader title={t('title')} level={2} />
      <PaginatedTable
        paginatedResource={nodeConfigsQueryResponse}
        columns={columns}
        rowKey={(config) => config.id}
        data-testid="configuration-table"
        onParamsChange={onFilterAndSortChange}
        dataSourceMapper={dataSourceMapper}
      />
    </Container>
  );
}

function getNodeConfigurationColumns(
  t: MimicTranslationFunction<'nodeConfigurations'>,
  tagsList: Tag[] | undefined,
  filters: NodeConfigFilters,
  getNodeConfigPath: (nodeConfig: SelectableNodeConfig) => string,
  getNodeConfigurationRevisionPath: (nodeConfigRevision: ConfigRevision) => string,
  onNodeConfigSelect?: (nodeConfig: SelectableNodeConfig) => void,
): ColumnsType<SelectableNodeConfig> {
  const allColumns: ColumnType<SelectableNodeConfig>[] = [
    {
      dataIndex: 'select',
      render: (_, nodeConfig) => {
        if (!onNodeConfigSelect) return null;
        return (
          <Radio
            dataTestId="select-node-config"
            onClick={() => onNodeConfigSelect(nodeConfig)}
            checked={nodeConfig.selected || false}
          />
        );
      },
    },
    {
      title: <span data-testid="name">{t('table.header.name')}</span>,
      dataIndex: 'name',
      sorter: true,
      filterMode: 'menu',
      render: (name: string, record) => {
        return (
          <Link
            to={getNodeConfigPath(record)}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            dataTestId="node-link"
          >
            <Flex gap="xxs">
              <BuildOutlined />
              <MimicHighlighter searchText={filters.name} text={name} />
            </Flex>
          </Link>
        );
      },
    },
    {
      title: <span data-testid="os">{t('table.header.os')}</span>,
      dataIndex: 'os',
      sorter: true,
      filters: [
        { text: 'Windows', value: 'windows' },
        { text: 'Linux', value: 'linux' },
      ],
      render: (operatingSystem: NodeConfig['os'], nodeConfiguration: NodeConfig) => (
        <Tooltip title={`${operatingSystem} ${nodeConfiguration.os}`} placement="right">
          <div data-testid="operating-system-icon">
            <OsIcon name={operatingSystem} />
          </div>
        </Tooltip>
      ),
      width: '70px',
    },
    {
      title: <span data-testid="latestConfigRevision">{t('table.header.revision')}</span>,
      dataIndex: 'latestConfigRevision',
      render: (latestConfigRevision: ConfigRevision) => (
        <Link
          to={getNodeConfigurationRevisionPath(latestConfigRevision)}
          dataTestId="revision-path"
        >{`#${latestConfigRevision.revisionNumber}`}</Link>
      ),
    },
    {
      title: <span data-testid="assigned">{t('table.header.assigned')}</span>,
      dataIndex: 'useCount',
      sorter: true,
      render: (assigned: string, record: NodeConfig) => (
        <Tooltip
          title={
            <div>
              <WarningFilled style={{ color: tokens.color.yellow.yellow06, padding: '4px 4px 2px 0' }} />
              {t('table.outOfDate', { nodeCount: record.outdatedUseCount })}
            </div>
          }
          placement="right"
        >
          {t('table.nodeCount', { nodeCount: assigned })}
          {record.outdatedUseCount > 0 && (
            <span>
              <WarningFilled style={{ color: tokens.color.yellow.yellow06, padding: '4px 0 2px 4px' }} />
            </span>
          )}
        </Tooltip>
      ),
    },
    {
      title: <span data-testid="tags">{t('table.header.tags')}</span>,
      dataIndex: 'tags',
      filters: tagsList?.map((tag) => {
        return { text: tag.name, value: tag.name };
      }),
      filterMode: 'menu',
      filterSearch: true,
      width: '20%',
      render: (tags: string[]) => {
        return <TagsList tags={tags} />;
      },
    },
    {
      title: <span data-testid="configId">{t('table.header.id')}</span>,
      dataIndex: 'id',
      filterMode: 'menu',
      render: (_, nodeConfiguration: NodeConfig) => {
        return (
          <Text copyable={{ text: nodeConfiguration.id }} mono>
            <MimicHighlighter searchText={filters.id} text={nodeConfiguration.id} />
          </Text>
        );
      },
    },
    {
      title: <span data-testid="dateUpdated">{t('table.header.lastRevised')}</span>,
      dataIndex: 'dateUpdated',
      sorter: true,
      key: 'dateUpdated',
      render: (dateUpdated) => {
        return <RelativeTime date={dateUpdated} />;
      },
      width: '200px',
    },
  ];

  if (!onNodeConfigSelect) {
    return allColumns.filter((column) => column.dataIndex !== 'select');
  }
  return allColumns;
}
