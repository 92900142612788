import { useEffect } from 'react';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export function useSubscribeToEvents<MessageType>(events: string | string[], callback: (payload: MessageType) => void) {
  const channel = useMgmtPlaneStateStore((state) => state.channel);

  useEffect(() => {
    if (!channel) return undefined;
    const subscribedEvents = Array.isArray(events) ? events : [events];
    const subscriptions = subscribedEvents.map((event) => {
      return {
        event,
        ref: channel.on(event, callback),
      };
    });

    return () => {
      if (!channel) return;
      subscriptions.forEach((subscription) => {
        channel.off(subscription.event, subscription.ref);
      });
    };
  }, [events, callback, channel]);
}
