/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Channel, Socket } from 'phoenix';
import { StateCreator } from 'zustand';

export interface WebsocketSlice {
  socket?: Socket;
  channel?: Channel;
  setSocket: (socket: Socket | undefined) => void;
  setChannel: (socket: Channel | undefined) => void;
}

export const createWebsocketSlice: StateCreator<WebsocketSlice, [], [], WebsocketSlice> = (set) => ({
  socket: undefined,
  channel: undefined,
  setSocket: (socket) => set(() => ({ socket })),
  setChannel: (channel) => set(() => ({ channel })),
});
